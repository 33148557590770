.card{
    flex-direction: column;
    display: flex;
    width: 10rem;
    height: 13rem;
    position: absolute;
    gap: 1rem;
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--pink);
    border-radius: 20px;
    box-shadow: var(--boxShadow);
    padding: 0px 26px 2rem 26px;
}

.card span:nth-of-type(2){
    color: var(--gray);
    font-size: 15px;
}

.card>img{
    transform: scale(0.6);
    margin-bottom: -2rem;
}

.c-button{
    background: #ffffff;
    box-shadow: 0px 19px 60px rgba(0,0,0,0.08);
    border-radius: 7px;
    border:none;
    padding: 10px;
    font-size: 16px;
    color: #5290fd;
}



@media screen and (max-width: 480px){
    .card{
        width: 270px;
    }
}