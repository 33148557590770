.n-wrapper{
    display: flex;
    height: 10vh;
    justify-content: space-between;
}

.n-left{
    display: flex;
    flex: 1;
    align-items: center;
    gap: 2rem;
}

.n-name{
    font-size:1.3rem ;
    font-weight: bold;
}

.n-right{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: bold;
}

.n-list{
    flex: 10;
    
}

.n-list>ul{
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
    cursor: pointer; 
}



.n-list>ul>li:hover{
    cursor: pointer;
    color: var(--pink)
}

.n-button{
    flex:2;
}


@media screen and (max-width: 480px){
    .n-list{
        display: none;
    }

    .n-button{
        display: none;
    }
}