:root
{
    --pink: hotpink;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
}

.button {
    background: hotpink;
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 11px 26px 11px 26px;
  }
  
  .button:hover {
    background: white;
    cursor: pointer;
    border: 1px solid var(--pink);
    color: var(--pink);
  }
  @media screen and (max-width: 480px) {
    .App {
      padding: 0.5rem 1rem;

    }
  }